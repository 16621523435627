import Turbolinks from 'turbolinks';
import Rails from '@rails/ujs';
import ahoy from 'ahoy.js';

Rails.start();
Turbolinks.start();

global.$ = jQuery;
global.jQuery = jQuery;
global.ahoy = ahoy;

document.addEventListener('turbolinks:load', () => {
  const properties = document.getElementById('ahoy-properties');
  if (properties) {
    ahoy.trackView(JSON.parse(properties.value));
  }
  gtag('js', new Date());
  fbq('track', 'PageView');
});

if (navigator.serviceWorker) {
  navigator.serviceWorker.register('/serviceworker.js').catch(() => {});
}
