const isMobile = () => /Android|iPhone|iPad|HeadlessChrome/i.test(navigator.userAgent);

const printOption = (item, escape) => `
    <div id='selectize_option_${item.id}'>
      <img width=30 height=30 src='${item.avatar_url}' />&nbsp;
      ${escape(item.name)}
      <small>
        ${escape(item.promoter_name ? `(${item.promoter_name})` : '')}
      </small>
    </div>
  `;

export default () => {
  window.$('select[url]').selectize({
    valueField: 'id',
    searchField: 'name',
    score() { return () => 1; },
    openOnFocus: false,
    load(query, callback) {
      this.clearOptions();
      if (query.length) {
        window.$.getJSON(this.$input.attr('url'), { q: query }).always(callback);
      } else {
        callback();
      }
    },
    render: {
      option: printOption,
      item: printOption,
    },
  });
  if (!isMobile()) {
    window.$('select.submittable[multiple]').selectize();
  }
};
