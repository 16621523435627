import 'core';
import 'popper.js';
import 'bootstrap';
import 'selectize';
import autosize from 'autosize';
import 'jquery.typewatch';
import 'jquery-mask-plugin';
import { validate as validateCPF } from 'gerador-validador-cpf';
import * as cnpj from '@fnando/cnpj';
import 'jquery-countdown';
import Swal from 'sweetalert2';
import 'sticky-kit/dist/sticky-kit.js';
import 'best_in_place';
import 'refile';
import 'cocoon';
import showTooltip from 'alisto.js/lib/show-tooltip';
import initCopy from 'alisto.js/lib/init-copy';
import toCurrency from 'alisto.js/lib/to-currency';
import cep from 'cep-promise';
import selectize from '../../assets/javascripts/lib/selectize';

if (typeof (nw) !== 'undefined') {
  window.alert('Windows!');
}

if (window.NodeList && !NodeList.prototype.forEach) {
  NodeList.prototype.forEach = Array.prototype.forEach;
}

Function.prototype.ToString = function () { return this.toString(); };

global.Swal = Swal;
global.showTooltip = showTooltip;
global.toCurrency = toCurrency;
global.selectize = selectize;

global.bestInPlace = function () {
  $('.best_in_place').best_in_place();
};

global.maskPhone = function () {
  $("input[type='tel']").mask('(00) 00000-0000');
};

global.maskCPF = function (input = "input[id*=cpf]:not([type='radio'])") {
  $(input).mask('000.000.000-00').keyup(function () {
    validateDocument(this, 14, validateCPF, 'CPF');
  });
};

global.maskCNPJ = function (input = "input[id*=cnpj]:not([type='radio'])") {
  $(input).mask('00.000.000/0000-00').keyup(function () {
    validateDocument(this, 18, cnpj.isValid, 'CNPJ');
  });
};

global.maskZipcode = function () {
  $('input[id*=zipcode]').mask('00000-000').on('input', function () {
    if (this.value.length === 9) {
      const form = $(this).parents('form');
      cep(this.value).then((data) => {
        form.find("[id$='_street']").val(data.street);
        form.find("[id$='_neighborhood']").val(data.neighborhood);
        form.find("[id$='_city']").val(data.city);
        form.find("[id$='_state']").val(data.state);
      }).catch(() => {
        window.alert('O CEP não foi encontrado, valide que está correto.');
      });
    }
  });
};

global.maskStateRegistration = function (input = 'input[id*=state_registration]') {
  $(input).mask('000.000.000.000');
};

global.maskDynamic = function (name) {
  $(`.${name}_document_type input`).change(function () {
    const input = $(`#${name}_document_number`);
    window[`mask${this.value.toUpperCase()}`](input);
    if (input.value !== '') {
      input.trigger('keyup');
    }
  }).filter(':checked').trigger('change');
};

global.sum = function (total, value) {
  return total + value;
};

global.isInStandaloneMode = function () {
  return window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone;
};

global.autocompleteByCPF = function (selector, clear) {
  $(selector).typeWatch({
    captureLength: 14,
    wait: 1,
    highlight: false,
    callback() {
      if (this.value.length > 0) {
        $.getScript(`${window.location}/user.js?cpf=${this.value}`);
      } else {
        $(clear).val('');
      }
    },
  });
};

const submitFormFor = function (element) {
  const submit = $(element).parents('form').find('input[type=submit]');
  if (submit.is(':hidden')) {
    submit.trigger('click.rails');
  }
};

global.openModal = (id = 'modal') => $(`#${id}`).modal('show');

global.openModalWith = function (html) {
  return $('#modal .modal-content').html(html).parents('#modal').modal('show');
};

const typeWatch = function () {
  document.querySelectorAll('input.submittable').forEach((input) => {
    $(input).typeWatch({
      captureLength: input.type === 'number' ? 1 : 0,
      wait: 500,
      highlight: false,
      callback() {
        if (document.body.contains(this)) {
          submitFormFor(this);
        }
      },
    });
  });
};

const hackFilterForms = function () {
  const form = $('form.filter');
  form.find('a').on('click', function () {
    form.find("li input[type='hidden']").val(null);
    $(this).siblings('input').val(true);
    submitFormFor(this);
  });
};

var validateDocument = function (input, size, fn, label) {
  if (input.value.length === size && fn(input.value)) {
    $(input).parent('div').removeClass('has-error').siblings('.feedback')
      .addClass('d-none');
    input.setCustomValidity('');
  } else {
    $(input).parent('div').addClass('has-error').siblings('.feedback')
      .removeClass('d-none');
    input.setCustomValidity(`${label} inválido`);
  }
};

$(document).on('change', 'select.submittable', function () {
  submitFormFor(this);
  if ($(this).attr('url')) {
    this.selectize.clear();
  }
});

$(document).on('focus', 'textarea', function () {
  autosize($(this));
});

$(document).on('click', 'form a.cancel', function (e) {
  e.preventDefault();
  $(this).parents('form')[0].reset();
});

$(document).on('click', 'a.viewer', function (e) {
  e.preventDefault();
  import('viewerjs').then((module) => {
    const viewer = new module.default(document.querySelector($(this).data('target')), {
      hide() { viewer.destroy(); },
      toolbar: {
        zoomIn: true,
        zoomOut: true,
        oneToOne: true,
        reset: true,
        rotateLeft: true,
        rotateRight: true,
        flipHorizontal: true,
        flipVertical: true,
        download() {
          const a = document.createElement('a');
          a.href = viewer.image.src;
          a.download = viewer.image.src.split('/').splice(-1, 1);
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        },
      },
    });
    viewer.show(true);
  });
});

$(document).on('click', '.stop-propagation', (e) => {
  e.stopPropagation();
});

$(document).on('shown.bs.collapse', '.collapse', (e) => {
  global.bestInPlace();
});

$(document).on('click', 'a.eraser', function (e) {
  e.preventDefault();
  $($(this).data('input')).val('').trigger('keyup');
});

$(document).on('turbolinks:load', () => {
  selectize();
  typeWatch();
  hackFilterForms();
  global.bestInPlace();
  initCopy();
  global.maskPhone();
  global.maskCPF();
  global.maskZipcode();
  global.maskCNPJ();
});

$(document).on('turbolinks:before-cache', () => {
  $('select.selectized').each(function () { this.selectize.destroy(); });
});
